import routeList from './routes/routerList'
import RouterView from './routes/RouterView'
import { HashRouter as Router } from 'react-router-dom'


const App = () => {
  return (
    <Router>
        <RouterView routeList={routeList} />
    </Router>
  );
}

export default App;
