import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'

import { Suspense } from 'react'

// 引入加载动画
import Load from '../components/load'


const RouterView=({routeList})=>{
    
    // 获取路由
    let list = routeList.filter(item=>!item.to)

    return (
        <Suspense fallback={<Load/>}>
            <Switch>
                {
                    list&&list.length>0?list.map((item,index)=>{
                        return <Route
                        path={item.path}
                        key={index}
                        render={routerProps=>{
                            let Com = item.component

                            if(item.children){
                                return <Com
                                {...routerProps}
                                routeList={item.children}
                                navlist={item.children.filter(item=>!item.to)}
                                />
                            }
                            
                            if(item.isRequired&&!localStorage.getItem('user')){
                                return <Redirect to={'/login'} from={item.path}/>
                            }

                            return <Com {...routerProps}/>
                        }}
                        
                        ></Route>
                    }):<p>暂无数据</p>
                },
                {
                    <Redirect to='/first' from ='/'/>
                }
            </Switch>
        </Suspense>
    )
}

export default RouterView