import {lazy} from 'react'

let Index =lazy(()=>import('../pages/app'))
let Home = lazy(()=>import('../pages/app/home'))
let First = lazy(()=>import('../pages/app/index'))
let Detail = lazy(()=>import('../pages/app/detail'))

let routeList = [
    {
        path:'/',
        component:Index,
        children:[
            {
                path:'/first',
                component:First
            },
            {
                path:'/news',
                component:Home
            },
            {
                path:'/detail/:id',
                component:Detail
            }
        ]
    },
    {
        from: '/',
        to: '/first'
    }
]

export default routeList